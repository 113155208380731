import { defineStore } from 'pinia';

export const useAbortEnrolmentStore = defineStore('abortEnrolmentStore', () => {
    var abortEnrolmentModal = ref<boolean>(false);
    var abortClicked = ref<boolean>(false); 
    var showCancelLoader = ref<boolean>(false); 
    var enrolmentId = ref<IAbortApplication>(""); 

    const getAbortEnrolmentModal = computed(
        () => {
            return abortEnrolmentModal.value;
        }
    );

    const getAbortBtnClicked = computed(
        () => {
            return abortClicked.value;
        }
    );

    function isOpenAbortEnrolmentModal() {
        abortEnrolmentModal.value = true;
    }
    function isClosedAbortEnrolmentModal() {
        abortEnrolmentModal.value = false;
    }

    function setAbortBtnClicked(abortBtnClicked: boolean) {
        abortClicked.value = abortBtnClicked;
    } 

    function setEnrolmentId(id: IAbortApplication) {
        enrolmentId.value = id;
    } 

    const getCancelApplicationLoader = computed(
        () => {
            return showCancelLoader.value;
        }
    );

    function setCancelApplicationLoader(visibility: boolean) {
        showCancelLoader.value = visibility;
    }   

    const getEnrolmentId = computed(() => enrolmentId.value);

    return {
        getAbortEnrolmentModal,
        isOpenAbortEnrolmentModal: isOpenAbortEnrolmentModal,
        isClosedAbortEnrolmentModal: isClosedAbortEnrolmentModal,
        getCancelApplicationLoader,
        getAbortBtnClicked, 
        getEnrolmentId: getEnrolmentId, 
        setAbortBtnClicked,
        setEnrolmentId,
        setCancelApplicationLoader 
    }
});